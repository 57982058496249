import React from 'react';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import settingsParams from '../../../../components/CategoryMenu/settingsParams';
import styleParams from '../../../../components/CategoryMenu/stylesParams';
import LinkText from '../../../common/components/link-text';
import { useSelector } from '../../../common/components/runtime-context';
import {
  getAllPostsFeedLabel,
  getCategoryMenuWidgetData,
} from '../../aggregated-category-menu/selectors';
import LinkList from '../link-list-new';

const CategoryMenuNew = () => {
  const styles = useStyles();
  const settings = useSettings();

  const showAllPostsCategory = settings.get(
    settingsParams.showAllPostsCategory,
  );

  const showPostCount = settings.get(settingsParams.showPostCount);

  const categoryMenuWidgetData = useSelector(getCategoryMenuWidgetData);
  const allPostsLabel = useSelector(getAllPostsFeedLabel);
  const links = React.useMemo(() => {
    const { categoryMenu } = categoryMenuWidgetData;
    const shouldShowAllPostsCategory =
      categoryMenu[0]?.display && showAllPostsCategory && allPostsLabel;

    return categoryMenu
      .filter((_, index) => !(index === 0 && !shouldShowAllPostsCategory))
      .map((category, index) => ({
        key: category.id,
        path: category.path,
        text: (
          <LinkText
            postCount={showPostCount ? category.display.postCount : null}
            a11yText={category.display.a11yText}
          >
            {shouldShowAllPostsCategory && index === 0
              ? allPostsLabel
              : category.display.text}
          </LinkText>
        ),
      }));
  }, [
    categoryMenuWidgetData,
    showAllPostsCategory,
    allPostsLabel,
    showPostCount,
  ]);

  return (
    <nav aria-label={categoryMenuWidgetData.areaLabel}>
      <LinkList
        links={links}
        emptyState={{ text: categoryMenuWidgetData.emptyState }}
        alignment={styles.get(styleParams.textAlignmentNew)}
      />
    </nav>
  );
};

export default CategoryMenuNew;
