import {
  createStylesParams,
  StyleParamType,
  type IStyleParam,
} from '@wix/tpa-settings';
import * as LEGACYSTYLEPARAMS from './legacyStyleParams';

type GetStyleParamType = {
  isMobile: boolean;
  getStyleParamValue: (param: IStyleParam) => any;
  paramName: IStyleParam;
  mobileParamName: IStyleParam;
};

const getStyleParamForDesignSettings = ({
  isMobile,
  getStyleParamValue,
  paramName,
  mobileParamName,
}: GetStyleParamType) => {
  if (isMobile) {
    const designSettingsEnabled = getStyleParamValue(
      LEGACYSTYLEPARAMS.isDesignSettingsEnabled,
    );
    if (designSettingsEnabled) {
      const mobileParamValue = getStyleParamValue(mobileParamName);
      if (typeof mobileParamValue !== 'undefined') {
        return mobileParamValue;
      }
    }
  }
  return getStyleParamValue(paramName);
};

const getStyleParamForLayoutSettings = ({
  isMobile,
  getStyleParamValue,
  paramName,
  mobileParamName,
}: GetStyleParamType) => {
  if (isMobile) {
    const designSettingsEnabled = getStyleParamValue(
      LEGACYSTYLEPARAMS.isLayoutSettingsEnabled,
    );
    if (designSettingsEnabled) {
      const mobileParamValue = getStyleParamValue(mobileParamName);
      if (typeof mobileParamValue !== 'undefined') {
        return mobileParamValue;
      }
    }
  }
  return getStyleParamValue(paramName);
};

export default createStylesParams<{
  backgroundColorHoverNew: StyleParamType.Color;
  textColorHoverNew: StyleParamType.Color;
  cornerRadiusNew: StyleParamType.Number;
  borderWidthNew: StyleParamType.Number;
  borderColorNew: StyleParamType.Color;
  separatorWidthNew: StyleParamType.Number;
  separatorColorNew: StyleParamType.Color;
  backgroundColorNew: StyleParamType.Color;
  textFontNew: StyleParamType.Font;
  textColorNew: StyleParamType.Color;
  textAlignmentNew: StyleParamType.Number;
  textPaddingHorizontalNew: StyleParamType.Number;
  textPaddingVerticalNew: StyleParamType.Number;
}>({
  backgroundColorHoverNew: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamForDesignSettings({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.backgroundColorHover,
        mobileParamName: LEGACYSTYLEPARAMS.backgroundColorHoverMobile,
      }),
  },
  textColorHoverNew: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamForDesignSettings({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.textColorHover,
        mobileParamName: LEGACYSTYLEPARAMS.textColorHoverMobile,
      }),
  },
  textFontNew: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamForDesignSettings({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.textFont,
        mobileParamName: LEGACYSTYLEPARAMS.textFontMobile,
      }),
  },
  cornerRadiusNew: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamForDesignSettings({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.cornerRadius,
        mobileParamName: LEGACYSTYLEPARAMS.cornerRadiusMobile,
      }),
  },
  borderWidthNew: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamForDesignSettings({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.borderWidth,
        mobileParamName: LEGACYSTYLEPARAMS.borderWidthMobile,
      }),
  },
  borderColorNew: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamForDesignSettings({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.borderColor,
        mobileParamName: LEGACYSTYLEPARAMS.borderColorMobile,
      }),
  },
  separatorWidthNew: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamForDesignSettings({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.separatorWidth,
        mobileParamName: LEGACYSTYLEPARAMS.separatorWidthMobile,
      }),
  },
  separatorColorNew: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamForDesignSettings({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.separatorColor,
        mobileParamName: LEGACYSTYLEPARAMS.separatorColorMobile,
      }),
  },
  backgroundColorNew: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamForDesignSettings({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.backgroundColor,
        mobileParamName: LEGACYSTYLEPARAMS.backgroundColorMobile,
      }),
  },
  textColorNew: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamForDesignSettings({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.textColor,
        mobileParamName: LEGACYSTYLEPARAMS.textColorMobile,
      }),
  },
  textAlignmentNew: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamForLayoutSettings({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.textAlignment,
        mobileParamName: LEGACYSTYLEPARAMS.textAlignmentMobile,
      }),
  },
  textPaddingHorizontalNew: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamForLayoutSettings({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.textPaddingHorizontal,
        mobileParamName: LEGACYSTYLEPARAMS.textPaddingHorizontalMobile,
      }),
  },
  textPaddingVerticalNew: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamForLayoutSettings({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.textPaddingVertical,
        mobileParamName: LEGACYSTYLEPARAMS.textPaddingVerticalMobile,
      }),
  },
});
