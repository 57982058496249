import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

export type ISettingsParams = {
  showAllPostsCategory: SettingsParamType.Boolean;
  showPostCount: SettingsParamType.Boolean;
};

export default createSettingsParams<ISettingsParams>({
  showAllPostsCategory: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  showPostCount: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
});
